<template>
  <div class="container-system">
    <v-container style="max-width: 77rem;">
      <v-row>
        <!-- System Text -->
        <v-col v-if="!$vuetify.breakpoint.mobile" cols="12" md="5" align-self="center">
          <div>
            <img src="../../assets/responsive.svg" alt="Responsividade">
          </div>
        </v-col>

        <!-- Side illustration -->
        <v-col cols="12" md="7">
          <v-tabs color="teal" v-model="currentTab" fixed-tabs :show-arrows="$vuetify.breakpoint.mobile">
            <v-tab v-for="section in sections" :key="section.label">{{ section.label }}</v-tab>
          </v-tabs>
          <h1 class="system-title mt-7">
            {{ currentSection.title }}
          </h1>
          <v-col cols="12" v-for="topic in currentSection.topics" :key="topic">
            <span class="system-mark mr-4"></span><span class="system-text">{{ topic }}</span>
          </v-col>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    sections: [
      {
        label: "Programa de Gestão",
        title: "Tenha controle e transparência através de uma gestão inteligente das suas equipes de acordo com a Nova Instrução Normativa Nº 24/2023",
        topics: [
          "Programas de Gestão e Projetos",
          "Planos de Trabalho e Entregas",
          "Plano de Entregas e Produtos",
          "Processo Avaliativo",
        ],
      },
      {
        label: "Planejamento de Contratações",
        title: "Garantia total de conformidade em seus planejamentos de contratações",
        topics: [
          "Equipe de Planejamento de Contratação",
          "Base de dados públicos",
          "Pesquisa de Preços e Acórdãos",
          "Ritos processuais aderentes a Lei 14.133",
        ],
      },
    ],
    currentTab: 0,
  }),
  computed: {
    currentSection() {
      return this.sections[this.currentTab];
    }
  },
}
</script>

<style scoped>
.system-title {
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Raleway;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #616161;
  margin-bottom: 1.5rem;
}

.system-mark {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: #80CBC4;
}

.system-text {
  color: var(--cores-neutras-neutra-escura-2, #212121);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Raleway;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.50019rem; /* 100.011% */
  letter-spacing: 0.03125rem;
}

.container-system {
  background-color: #FFFF;
  margin-top: 8rem;
}

@media only screen and (max-device-width: 700px) {
  img {
    max-width: 17rem;
    margin-bottom: 1rem;
  }

  .container-system {
    width: 100vw;
    margin-top: 1.5rem;
  }

  .system-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80vw !important;
    margin: 1rem;
    font-size: 1.2rem !important;
  }

  .system-mark {
    position: relative;
    top: 2.5rem;
  }

  .system-title {
    font-size: 1.5rem !important;
    width: 90vw !important;
    margin: 1rem;
  }

  .home-slogan {
    font-size: 1rem !important;
  }
}
</style>
