<template id="home">
  <div id="page-landing" style="background-color: #F5F5F5;">
    <v-container class="container-home">
      <v-col
        cols="12"
        md="4"
        v-if="$vuetify.breakpoint.mobile"
      >
        <transition appear name="slide-fade-reverse">
          <div class="ilustration-mobile">
            <img src="../../assets/home.svg" alt="Ilustração">
          </div>
        </transition>
      </v-col>
      <v-row>
        <v-col cols="12" md="7"> 
          <transition
            appear
            name="slide-fade"
          >
            <div class="home-text">
              <h1 class="home-title">Facilite e inove os seus Programas de Gestão</h1>
              <p class="home-slogan">Faça a diferença com seus planejamentos de contratações e modelos de programas de gestão</p>

              <v-row class="input-content">
                <v-col>
                  <v-text-field 
                    color="teal" 
                    style="min-width: 303px;" 
                    class="mr-4"
                    type="email"
                    v-model="contact.email"
                    :rules="[rules.required, rules.email]"
                    messages="Não enviaremos nenhum tipo de spam"
                    label="Insira seu melhor e-mail aqui">
                  </v-text-field>
                  <p v-if="error" class="red--text text--lighten-1">{{error}}</p>
                  <p v-if="success" class="teal--text text--darken-1">Recebemos suas informações e em breve entraremos em contato <v-icon class="teal--text text--darken-1">mdi-check-circle-outline</v-icon></p>
                </v-col>
                <v-col>
                <v-btn 
                  class="float-right text-capitalize home-btn"
                  color="teal"
                  @click="sendMail"
                > 
                  <span class="btn-text" style="color: white;">Solicitar Teste</span></v-btn>
                </v-col>
              </v-row>
            </div>
          </transition>
        </v-col>
        <v-spacer></v-spacer>
        
        <!-- Side illustration -->
        <v-col
          cols="12"
          md="4"
          class="float-right"
          v-if="!$vuetify.breakpoint.mobile"
          style="display: flex; justify-content: center;"
        >
          <transition appear name="slide-fade-reverse">
            <div class="ilustration">
              <img src="../../assets/home.svg" alt="Ilustração">
            </div>
          </transition>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    success: false,
    contact: {
      email: '',
    },
    rules: {
      required: value => !!value || 'Campo obrigatório',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'E-mail invlálido'
      },
    },
  }),
  methods: {
    async sendMail(){
      this.loading = true
      this.error = ''
      try {
        await this.$axios.post('https://novo.smitt.com.br/api/v1/get_in_contact', this.contact)
      } catch (error) {
        if(error.response && error.response.data){
          this.error = error.response.data.errors
        } else {
          this.error = 'Falha ao enviar informações de contato'
        }
      }
      this.success = !this.error
      this.loading = false
    }
  }
}
</script>

<style scoped>
#page-landing {
  display: flex;
  background-color: #F5F5F5;
}

.container-home {
  max-width: 77rem;
}

.home-text {
  display: flex;
  width: 34.625rem;
  flex-direction: column;
  align-items: flex-start;
}

.home-title {
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Raleway;
  font-size: 3rem !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #616161;
}

.home-btn {
  display: flex;
  height: 3.25rem;
  padding: 0rem 1rem;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

.btn-text {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  text-align: center;
  font-size: 0.90rem;
  line-height: 2.25rem;
}

.home-slogan {
  color: #616161; 
  font-size: 24px;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Raleway;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ilustration {
  display: flex;
  padding: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.slide-fade-enter-active, .slide-fade-reverse-enter-active {
  transition: all 1.8s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}

.slide-fade-reverse-enter, .slide-fade-reverse-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

@media only screen and (max-device-width: 700px) {
  #page-landing {
    min-height: 52rem;
  }
  .container-home {
    width: 100vw;
  }

  .home-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80vw !important;
    margin: 0px;
    margin-top: 2rem;
    margin-left: 1.5rem;
  }

  .home-title {
    font-size: 1.5rem !important;
  }

  .home-slogan {
    font-size: 1rem !important;
  }

  .ilustration-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 315px;
    padding: 0px;
  }

  img {
   width: 310px;
   height: 300PX;
   margin: 0;
  }
}
</style>
