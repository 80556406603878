<template>
  <v-container id="page-landing">
    <v-row>
      <!-- Register Form -->
      <v-col
        cols="12"
        md="6"
      >
        <template>
          <p class="contact-title">Cadastre-se</p>   
          <v-form
            ref="form"
            v-model="isValid"
            lazy-validation
          >
            <v-text-field
              v-model="register.name"
              :rules="[rules.required]"
              label="Nome"
              color="teal"
            ></v-text-field>

            <v-text-field
              v-model="register.email"
              :rules="[rules.required, rules.email]"
              label="E-mail"
              color="teal"
            ></v-text-field>

            <v-text-field
              v-model="register.institution"
              :rules="[rules.required]"
              label="Instituição"
              color="teal"
            ></v-text-field>

            <v-select
              v-model="register.institution_type"
              :items="institution_types"
              :rules="[rules.required]"
              item-text="name"
              item-value="value"
              no-data-text="Dados não encontrados"
              label="Tipo de Instituição"
              persistent-hint
              color="teal"
            />

            <v-text-field
              v-model="register.cell"
              :rules="[rules.required]"
              label="Telefone"
              v-mask="['(##) ####-####', '(##) #####-####']"
              color="teal"
            ></v-text-field><br/><br/>

            <!-- <v-btn
              color="teal"
              class="mr-4"
              dark 
              @click="createTrial"
              @change="confirm = !confirm"
            >
              Enviar
            </v-btn> -->

            <v-row>
              <v-dialog
                v-model="dialog"
                persistent
                width="450"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="teal"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="createTrial"
                  >
                    Enviar
                  </v-btn>
                </template>
                <v-card justify="center" style="justify-content: center;">
                  <v-card-title class="justify-center">
                    <v-progress-circular
                      v-if="loading"
                      indeterminate
                      color="teal"
                    ></v-progress-circular>
                    <v-icon v-else-if="error" color="orange" x-large>mdi-alert-circle</v-icon>
                    <v-icon v-else color="green" x-large>mdi-check-circle</v-icon>
                  </v-card-title>
                  <v-card-text class="text-center">
                    <span class="body-1" v-if="error" v-text="error"/>
                    <span class="body-1" v-else>
                      Seus dados de acesso foram enviados para o endereço de email "{{register.email}}" informado!
                    </span>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="error && !loading"
                      color="grey"
                      text
                      @click="dialog = false"
                      small
                    >
                      Fechar
                    </v-btn>
                    <v-btn
                      v-else-if="!loading"
                      color="green"
                      text
                      @click="dialog = false"
                      small
                      to="/"
                    >
                      Voltar a pagina inicial
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </v-form>
        </template>
      </v-col>

      <!-- ilustration -->
      <template>
        <v-spacer></v-spacer>
        <v-row>
          <v-col
            cols="14"
            md="8"
          ><br/><br/>
            <div v-if="!$vuetify.breakpoint.mobile"> 
              <img src="../../assets/bigLogo.svg" alt="ilustration">
              <p style="margin-left: 50px;">Conheça como nossa solução funciona</p>
            </div>
          </v-col>
        </v-row>
      </template>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    register: {
      name: '',
      email: '',
      institution: '',
      cell: '',
      domain: '',
      institution_type: '',
    },
    institution_types: [
      { name: 'Instituição Privada', value: 'private' },
      { name: 'Instituição Pública', value: 'public'  },
    ],
    rules: {
      required: value => !!value || 'Campo obrigatório',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'E-mail invlálido'
      },
    },
    confirm: false,
    dialog: false,
    loading: false,
    isValid: false,
    error: '',
  }),
  methods: {
    createTrial(){
      if(this.isValid){
        this.sendRequest()
      }else{
        this.error = 'Favor preencher todos os campos obrigatórios'
      }
    },
    async sendRequest(){
      this.loading = true
      this.error = ''
      try {
        await this.$axios.post('https://novo.smitt.com.br/api/v1/trial_register', this.register)
      } catch (error) {
        if(error.response.data){
          this.error = error.response.data.errors
        }
      }
      this.loading = false
    }
  },
}
</script>

<style scoped>
p {
  font-family: 'Comfortaa', cursive;
  font-weight: 300;
  font-size: 25px;
  
}

span {
  color: #657472; 
  font-size: 22px;
  font-family: 'Comfortaa', cursive;
}
.contact-title {
  font-family: 'Hind Madurai', sans-serif; 
  color: #009688;
  font-weight: 400;
  font-size: 45px;
}
</style>