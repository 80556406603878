import Vue from 'vue'
import VueRouter from 'vue-router'
import LandPage from '../views/LandPage/LandPage'
import Register from '../views/Register/Register'
import Contact from '../views/Contact/Contact'
import PrivacyPolicy from '../views/PrivacyPolicy/PrivacyPolicy'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LandPage',
    component: LandPage
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/contact',
    name: 'Contato',
    component: Contact,
  },
  {
    path: '/privacy-policy',
    name: 'Política de Privacidade',
    component: PrivacyPolicy
  },
  {
    path: '/*',
    beforeEnter () {
      window.location = '/'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
