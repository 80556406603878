<template>
  <div class="justify-sm-center align-self-center mt-6" style="height: 20rem;">
    <v-row align="center">
      <v-col cols="12">
        <div class="card-alert">
          <h1 class="about-title">Quer garantir total conformidade em seu programa de gestão?</h1>
          <v-row>
            <v-col md="9" sm="12" xs="12">
              <v-text-field
                label="Insira seu melhor e-mail aqui"
                color="teal"
                outlined
                class="mt-3"
                messages="Não enviaremos nenhum tipo de spam"
                v-model="contact.email"
                :rules="[rules.required, rules.email]"
              >
              </v-text-field>
              <p v-if="error" class="red--text text--lighten-1">{{error}}</p>
              <p v-if="success" class="teal--text text--darken-1">Recebemos suas informações e em breve entraremos em contato <v-icon class="teal--text text--darken-1">mdi-check-circle-outline</v-icon></p>
            </v-col>
            <v-col cols="12" md="3" sm="12" xs="12">
              <v-btn 
                class="text-capitalize about-btn" 
                color="teal"
                @click="sendMail"
              > 
              <span class="btn-text" style="color: white;">Solicitar Teste</span>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    success: false,
    contact: {
      email: '',
    },
    error:false,
    rules: {
      required: value => !!value || 'Campo obrigatório',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'E-mail invlálido'
      },
    },
  }),
  methods: {
    async sendMail(){
      this.loading = true
      this.error = ''
      try {
        await this.$axios.post('https://novo.smitt.com.br/api/v1/get_in_contact', this.contact)
      } catch (error) {
        if(error.response && error.response.data){
          this.error = error.response.data.errors
        } else {
          this.error = 'Falha ao enviar informações de contato'
        }
      }
      this.success = !this.error
      this.loading = false
    }
  }
}
</script>

<style scoped>
.card-alert {
  display: flex;
  flex-direction: column;

  width: 39.75rem;
  height: 14.5rem;
  padding: 2.5rem;
  
  align-items: center;
  gap: 2rem;
  flex-shrink: 0;
  background: #F5F5F5;
  border-radius: 1rem;
}

.about-title {
  color: var(--cores-neutras-cor-cinza-neutra, #616161);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Raleway;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-self: stretch;
}

.about-btn {
  display: flex;
  height: 3.40rem !important;
  align-items: center;
  gap: 1rem;
  margin-top: 0.8rem;
}

.btn-text {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  text-align: center;
  font-size: 0.90rem;
  line-height: 2.25rem;
}

@media only screen and (max-device-width: 700px) {
  .about-btn {
    margin-left: 3rem !important;
    margin-top: 1rem !important;
    position: relative;
    bottom: 2.5rem;
  }
  .card-alert {
    width: 20rem;
    height: 26em;
    padding: 2.5rem;
  }
  .about-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80vw !important;
    margin: 1rem;
  }

  .about-title {
    font-size: 1.2rem !important;
    margin: 0.8rem;
  }

  .home-slogan {
    font-size: 1.5rem !important;
  }
}

</style>
