<template>
  <div class="footer">
    <div class="justify-sm-center align-self-center mt-6">
      <v-row class="align-self-center" align="center">
        <v-col cols="12">
          <div id="logo" class="logo pr-2" style="display: flex; justify-content: center;">
            <img src="../../assets/brand.svg" alt="ilustration">
          </div>
          <div class="links-class" style="display: flex; justify-content: center;">
            <v-btn
              v-for="link in links"
              :key="link.icon"
              :href="link.link"
              icon
              color="teal"
            >
              <v-icon size="1.5rem">{{ link.icon }}</v-icon>
            </v-btn>
          </div>
          <p class="text-info">Iniciativa Grupo Spezi</p>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
 data: () => ({
  links: [
    {icon: 'mdi-linkedin', link: 'https://www.linkedin.com/in/smitt-programas-de-gest%C3%A3o-facilitado-265569206/'},
    { icon: 'mdi-email', link: 'mailto:contatosmitt@spezi.com.br'},
    { icon: 'mdi-phone', link: 'tel:(61) 2099 2433'},
  ],
  items: [
    'default',
    'absolute',
    'fixed',
  ],
}),
}
</script>

<style scoped>
.footer {
  background: #F5F5F5;
  display: flex;
  justify-content: center;
  height: 15.9375rem;
  flex-shrink: 0;
  align-items: center;
  width: 100vw;
}

.text-info {
  color: var(--cores-neutras-cor-cinza-neutra, #616161);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Raleway;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media only screen and (max-device-width: 700px) {
  img {
    margin: 0rem !important;
  }
}
</style>