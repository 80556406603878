<template>
  <v-app>
    <div style="background-color: #F5F5F5;">
      <v-container style="max-width: 80rem;">
        <v-col cols="12" v-if="!hideMenu">
          <v-card class="navbar">
            <v-row align="center">    
              <v-col cols="3">
                <router-link to="/">
                  <img class="logo" src="./assets/brand.svg" alt="SMITT" style="cursor: pointer;">
                </router-link>
              </v-col>          
            
              <v-col cols="5" style="justify-content: center;">
                <div v-if="!$vuetify.breakpoint.mobile">
                  <v-row 
                    justify="space-around"
                  >      
                    <v-btn
                      text
                      color="teal"
                      class="text-capitalize"
                      @click="toTop"
                    >
                      <span>Início</span>
                    </v-btn>

                    <v-btn
                      text
                      color="teal"
                      class="text-capitalize"
                      @click="$vuetify.goTo(target, options)"
                    >
                      <span>Sobre</span>
                    </v-btn>
                    <v-btn
                      text
                      color="teal"
                      class="text-capitalize"
                      @click="$vuetify.goTo(targetMotivation, options)"
                    >
                      <span>Funcionalidades</span>
                    </v-btn>
                  </v-row> 
                </div>
              </v-col>
              <v-col cols="4"  v-if="!$vuetify.breakpoint.mobile">
                <v-btn 
                  class="float-right text-capitalize" 
                  color="teal"
                  height="36px"
                  @click="$vuetify.goTo(targetContact, options)"
                > 
                <span style="color: white;">Solicitar Teste</span></v-btn>
              </v-col>
              <v-col cols="4" v-else>
                <v-btn 
                  class="float-right text-capitalize" 
                  color="teal"
                  height="36px"
                  @click="$vuetify.goTo(targetContactMobile, options)"
                > 
                <span style="color: white;">Solicitar Teste</span></v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col> 
        <v-col
          cols="12">
          <div class="banner"
            @click="$vuetify.goTo(targetContactMobile, options)"
          >
            <img src="@/assets/banner_smitt2.png" alt="Banner Promocional">
          </div>
        </v-col>
      </v-container>
    </div>
    <v-main >
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
let prevScrollpos = window.pageYOffset;
window.onscroll = function() {
  let currentScrollPos = window.pageYOffset;
  if (prevScrollpos > currentScrollPos) {
    document.querySelector(".navbar").style.top = "0";
  } else {
    document.querySelector(".navbar").style.top = "-60px"; // Altura do menu
  }
  prevScrollpos = currentScrollPos;
}


export default {
  name: 'App',
  data: () => ({
    fab: false,
    offset: -422,

    type: 'number',
    contact: 'value',
    motivations: 'valueMotivation',
    contactMobile: 'valueMobile',

    value: 550,
    valueMobile: 1100,
    number:200,
    valueMotivation: 1000,
  }),
  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0)
    },
  },
  computed: {
    hideMenu() {
      return this.$route.meta.hideMenu || false
    },
    target () {
      const value = this[this.type]
      if (!isNaN(value)) return Number(value)
      else return value
    },
    options () {
      return {
        offset: this.offset,
      }
    },
    targetContact () {
      const value = this[this.contact]
      if (!isNaN(value)) return Number(value)
      else return value
    },
    targetContactMobile () {
      const value = this[this.contactMobile]
      if (!isNaN(value)) return Number(value)
      else return value
    },
    targetMotivation () {
      const value = this[this.motivations]
      if (!isNaN(value)) return Number(value)
      else return value
    },
  },
};
</script>

<style scoped>
.navbar {
  display: flex;
  padding: 0.5rem 1rem;
  align-items: center;
  gap: 1.5rem;
}

.v-card {
  box-shadow: none !important;
}

.v-btn {
  display: flex;
  height: 2.25rem;
  padding: 0rem 1rem;
  align-items: center;
  gap: 1rem;
}

span {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  color: #009688;
  text-align: center;
  font-size: 1rem;
  line-height: 2.25rem;
}

.logo {
  margin-top: 5px;
}

.banner{
  img {
    cursor: pointer;
    width: 100%;
  }
}

@media only screen and (max-device-width: 700px) {
  .navbar {
    height: 4rem;
  }
  
  .logo {
    margin: 0rem !important;
  }
}
</style>
