<template>
  <v-app>
    <Home/>
    <System/>
    <About/>
    <!-- Scroll to top -->
    <v-btn
      v-scroll="onScroll"
      v-show="fab"
      fab
      dark
      fixed
      bottom
      right
      color="teal"
      @click="toTop"
    >
      <v-icon>mdi-chevron-up</v-icon>
    </v-btn>
    
    <Motivation/>
    <Contact/><br/><br/>
    <Footer/>
  </v-app>
</template>

<script>
import Home from '../Home/Home'
import System from '../System/System';
import About from '../About/About'
import Motivation from '../Motivations/Motivation';
import Contact from '../Contact/Contact';
import Footer from '../Footer/Footer.vue';

export default {
  name: 'App',

  components: {
    Home,
    System,
    About,
    Motivation,
    Contact,
    Footer,
  },

  data: () => ({
    fab: false
  }),

  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0)
    }
  },
};
</script>

<style scoped>
span {
  font-family: 'Hind Madurai', sans-serif; 
  color: #009688;
  font-weight: 500;
  font-size: 20px;
}

.logo {
  margin-top: 5px;
  
}
</style>
